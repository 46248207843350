import request from '@/util/request'
import { GTResponse } from '@/util/request/types';
const URL = process.env.VUE_APP_API_URL;
// 请求配置
export interface FormState {
  name: string,
  company: string,
  phone: string,
  mailbox: string,
  remark: string
}
// 开始抽奖
export const applyShop = (formState: FormState): Promise<GTResponse> => {
  return request.post(`${URL}/api/v2/version/applyShop`, formState, {isNeedToast: true})
};
