 
import { defineComponent, toRefs, reactive } from 'vue'
import { applyShop } from '@/api/joinUs'
import { message } from 'ant-design-vue';

export default defineComponent({
  name:'JoinUs',
  props: {
    isOpen: Boolean,
    closeFun: Function
  },
  setup(props) {
    const state = reactive({
      open: true,
    });
    const formState = reactive({
      name: '',
      company: '',
      phone: '',
      mailbox: '',
      remark: ''
    });
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    const validateMessages = {
      required: '${label}不为能空!'
    };
    const cancel = ()=>{
      props.closeFun && props.closeFun();
    }
    const onFinish = () => {
      applyShop(formState).then((resp)=>{
        cancel()
        message.success('操作成功');
      })
    };
    return {
      cancel,
      layout,
      formState,
      onFinish,
      validateMessages,
      ...toRefs(state),
    };
  }
});
