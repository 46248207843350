import { createRouter, createMemoryHistory, RouteRecordRaw } from 'vue-router'
import index from '../views/index/index.vue';
import cxample from '../views/cxample/index.vue';
import about from '../views/about/index.vue';

export const routesMap: Array<RouteRecordRaw> = [{
	path: '/',
	redirect: '/index'
}, {
  path: '/index',
  name: 'index',
  meta: {
    title: '上海海湾汇信息科技有限公司'
  },
  component: index
}, {
    path: '/cxample',
    name: 'cxample',
    meta: {
      title: '上海海湾汇信息科技有限公司'
    },
    component: cxample
  }, {
    path: '/about',
    name: 'about',
    meta: {
      title: '上海海湾汇信息科技有限公司'
    },
    component: about
  }
]
const router = createRouter({
  linkActiveClass: 'router-link-active',
  history: createMemoryHistory(),
  routes: routesMap
})
let isFirstBrowse = true;
router.beforeEach((to, from, next) => {
  document.title = (to.meta.title || '') + ''
  if (isFirstBrowse) {
		isFirstBrowse = false;
		if (to.path != location.pathname) {
			next({ path: location.pathname })
			return;
		}
	}
  window.history.replaceState({}, "", to.fullPath); //修改url地址
	next();
});
export default router;