 
// import { useRoute } from "vue-router";
import offset from "cy_static/offset"; 
import { defineComponent,  watch } from 'vue'
import { myHead, myFooter } from '@/components'
import { useRouter, useRoute} from 'vue-router'

export default defineComponent({
  components: {
    myHead, myFooter
  },
  setup() {
      const route = useRouter()
      const currRoute = useRoute()
      window.scrollTo({top: 0})
      const runPos = (hash: string) =>{
        if (hash) {
          var pos = offset(`#${hash}`);
          window.scrollTo({
            top: pos.top - 100,
            behavior: "smooth"
          })
        } else {
          window.scrollTo({top: 0, behavior: "smooth"})
        }
      }
      const hash = currRoute.query.hash + ''
      hash && setTimeout(()=>{runPos(hash)},300)
      watch(route.currentRoute, (route) => {
        const hash = route.query.hash + ''
        hash && runPos(hash)
      });
    return {
    };
  }
});
