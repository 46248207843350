import type { AxiosInstance } from 'axios';
import type { GTAxiosRequestConfig, GTAxiosResponse, GTResponse } from './types';

import axios from 'axios';
import { defaultRequestConfig, defaultAuthConfig, defaultErrorCodeMap, getErrMsg } from './helper';
import { message } from 'ant-design-vue';

// 创建axios实例
export const requestInstance: AxiosInstance = axios.create({baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/',});

// 请求拦截器
requestInstance.interceptors.request.use(
  (config: GTAxiosRequestConfig) => {
    let useConfig = defaultRequestConfig;
    if (config) {
      useConfig = { ...useConfig, ...config };
    }
    const { headers, gtAuth } = useConfig;
    const { isNeed, name, tokenPrefix } = gtAuth || defaultAuthConfig;
    if (headers && isNeed) {
      const token = '';
      headers[name] = `${tokenPrefix}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
requestInstance.interceptors.response.use(
  (response: GTAxiosResponse) => {
    const { successCodes, isNeedToast } = response.config;
    const checkSuccessCodes: number[] = successCodes || [100200];
    if (checkSuccessCodes.filter((v) => v == response.data.code).length) {
      return response.data;
    } else {
      if (isNeedToast) {
        const msg = getErrMsg(response);
        message.error(msg);
      }
      return Promise.reject(response);
    }
  },
  (error) => {
    const response = error.response || {};
    const resultCode = error.code || response.status || -1;
    const statusText = error.message || error.error || response.statusText;
    !response.data && (response.data = {});
    response.data.code = resultCode;
    response.data.msg = defaultErrorCodeMap[resultCode] || statusText;
    const isNeedToast = response.config ? response.config.isNeedToast : false;
    if (isNeedToast) {
      message.error(response.data.msg);
    }
    // TODO: Gavin - 跳转登录
    if (resultCode == 401) {
      // goLogin();
    }
    return Promise.reject(response);
  }
);

export const http = {
  /**
   * get 请求方式
   * @param url 接口地址
   * @param config 自定义配置
   * @returns
   */
  get<T = GTResponse>(url: string, config?: GTAxiosRequestConfig): Promise<T> {
    return requestInstance.get(url, config);
  },
  /**
   * post 请求方式
   * @param url 接口地址
   * @param data 请求数据
   * @param config 自定义配置
   * @returns
   */
  post<T = GTResponse>(url: string, data?: any, config?: GTAxiosRequestConfig): Promise<T> {
    return requestInstance.post(url, data, config);
  },
  /**
   * put 请求方式
   * @param url 接口地址
   * @param data 请求数据
   * @param config 自定义配置
   * @returns
   */
  put<T = GTResponse>(url: string, data?: any, config?: GTAxiosRequestConfig): Promise<T> {
    return requestInstance.put(url, data, config);
  },
  /**
   * delete 请求方式
   * @param url 接口地址
   * @param config 自定义配置
   * @returns
   */
  delete<T = GTResponse>(url: string, config?: GTAxiosRequestConfig): Promise<T> {
    return requestInstance.delete(url, config);
  }
};

export default http;
