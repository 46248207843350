 
import { myHead, myFooter, JoinUs } from '@/components'
import { useRouter, useRoute} from 'vue-router'
import offset from "cy_static/offset"; 
import { defineComponent, reactive, toRefs, onMounted, onUnmounted,  watch  } from "vue";
export default defineComponent({
  components: {
    myHead, myFooter, JoinUs
  },
  setup() {
    const route = useRouter()
    const currRoute = useRoute()
    window.scrollTo({top: 0})
    const runPos = (hash: string) =>{
      if (hash) {
        var pos = offset(`#${hash}`);
        window.scrollTo({
          top: pos.top - 80,
          behavior: "smooth"
        })
      } else {
        window.scrollTo({top: 0, behavior: "smooth"})
      }
    }
    const hash = currRoute.query.hash + ''
    hash && setTimeout(()=>{runPos(hash)},300)
    watch(route.currentRoute, (route) => {
      const hash = route.query.hash + ''
      hash && runPos(hash)
    });

    const state = reactive({
      currIndex: 0,
      isOpen: false,
      isScroll: false
    });
    // 关闭当前窗口
    const setIndex = (index:number) => {
      state.currIndex = index
    };
    const setJoinUs=(isOpen: boolean)=>{
      state.isOpen = isOpen
    }
    const goBackTop = (e: any) => {
      const scrollTop = document.documentElement.scrollTop
      state.isScroll = scrollTop > 1
    };
    onMounted(()=>{
      window.addEventListener('scroll', goBackTop);
    })
    onUnmounted(()=>{
      window.removeEventListener('scroll', goBackTop);
    })
    return {
      setIndex,
      setJoinUs,
      ...toRefs(state),
    };
  }
});
