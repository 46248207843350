 
import { useRoute } from "vue-router";
import JoinUs from './joinUs.vue'
import { defineComponent, toRefs, reactive } from 'vue'

export default defineComponent({
  name:'MyHead',
  components: {
    JoinUs
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      path: route.path,
      isOpen: false
    });
    const setJoinUs=(isOpen: boolean)=>{
      state.isOpen = isOpen
    }
    return {
      setJoinUs,
      ...toRefs(state),
    };
  }
});
