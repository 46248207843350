import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';

/**
 * 应用每次进入都会调用 mount 方法，通常我们在这里触发应用的渲染方法
 */
let instance: any;
function render(props: any) {
  instance = createApp(App).use(router).use(Antd);
  instance.mount('#app');
}
render(null)
