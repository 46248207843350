<template>
  <div class="nav micro-sps">
    <router-view v-slot="{ Component }">
      <keep-alive :include="cachedViews">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>
<script>
import { onMounted } from 'vue';

export default {
  setup() {
    // 消息提示离顶上112px
    // onMounted(() => {
    // })
    return {
      cachedViews:[]
    }
  }
}
</script>
 <style>

.middle {
  width: 1300px;
  margin: 0 auto;
  color: #202122;
}
</style>
