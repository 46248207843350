import type { GTAxiosRequestConfig, GTAxiosRequestAuthConfig, GTErrorCodeMap } from '@/util/request/types';

export const defaultAuthConfig: GTAxiosRequestAuthConfig = {
  isNeed: true,
  name: 'Gtcompanyid',
  tokenPrefix: 'CiN25HibDVWuu52pc1'
};

export const defaultRequestConfig: GTAxiosRequestConfig = {
  gtAuth: defaultAuthConfig,
  isNeedToast: false,
  successCodes: [200],
  headers: {}
};

export const defaultErrorCodeMap: GTErrorCodeMap = {
  '401': '认证失败, 请重新登录!',
  '403': '权限不足',
  '404': '请求错误,未找到该资源',
  '500': '服务器端出错',
  '503': '服务不可用',
  '504': '网络超时',
  '-1': '请求异常, 请稍后再试'
};

export const getErrMsg = (error: any, defaultMsg = '请求异常, 请稍后再试') => {
  let msg = defaultMsg;
  if (error && error.data && error.data.msg) {
    msg = error.data.msg;
  } else if (error && error.msg) {
    msg = error.msg;
  }
  return msg;
};
